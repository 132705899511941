import React, { useState, useEffect } from 'react'

export default function Pagination({ data, setPageData }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    useEffect(() => {
        
        setPageData(paginatedData)        
        // eslint-disable-next-line
    }, [data, currentPage]);

    useEffect(()=>{
        if(currentPage>totalPages){
            setCurrentPage(totalPages)
        }else{
            setPageData(paginatedData)
        }

    },[itemsPerPage])

    useEffect(() => {
        setCurrentPage(1)
    }, [data])

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const paginatedData = data.slice(indexOfFirstItem, indexOfLastItem)

    const totalPages = Math.ceil(data.length / itemsPerPage)

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handlePagePlus = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1)
        }
    }

    const handlePageMinus = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    
        return (
            <div className='d-flex justify-content-between flex-wrap'>
                <div className='d-flex'>
                    <span className='align-self-center pe-2'>Filas por página:</span>
                    <div className='dropdown align-self-center' style={{ outline: "none" }}>
                        <button className="btn dropdown-toggle" style={{ outline: "none" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {itemsPerPage}
                        </button>
                        <ul className='dropdown-menu'>
                            <li><button className='dropdown-item' type='button' onClick={() => setItemsPerPage(5)}>5</button></li>
                            <li><button className='dropdown-item' type='button' onClick={() => setItemsPerPage(10)}>10</button></li>
                            <li><button className='dropdown-item' type='button' onClick={() => setItemsPerPage(20)}>20</button></li>
                            <li><button className='dropdown-item' type='button' onClick={() => setItemsPerPage(30)}>30</button></li>
                            <li><button className='dropdown-item' type='button' onClick={() => setItemsPerPage(50)}>50</button></li>
                            <li><button className='dropdown-item' type='button' onClick={() => setItemsPerPage(100)}>100</button></li>
                        </ul>
                    </div>
                </div>
                {(data.length / itemsPerPage > 1) &&
                <div className='d-flex'>
                    <nav aria-label="Page navigation example" className='align-self-center'>
                        <ul className="pagination pagination-sm mb-0 d-flex flex-wrap">
                            <li className="page-item" onClick={() => handlePageMinus()}>
                                <div className="page-link user-select-none" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                    {/* <span className="sr-only">Previous</span> */}
                                </div>
                            </li>
                            {
                                Array.from({ length: totalPages }, (_, index) => (
                                    <li key={`pagin-${index}`} className={`page-item user-select-none ${currentPage === index + 1 ? "active" : ""}`} onClick={() => handlePageChange(index + 1)}><span className="page-link">{index + 1}</span></li>
                                ))
                            }
                            <li className="page-item" onClick={() => handlePagePlus()}>
                                <div className="page-link user-select-none" aria-label="Next">
                                    {/* <span className="sr-only">Next</span> */}
                                    <span aria-hidden="true">&raquo;</span>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
                }
            </div>
        )
    
}

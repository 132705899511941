import React,{useState} from 'react'
import {encriptar,desencriptar,setLocalStorage} from '../../utils/funciones'
import {PARAMETRO_SISTEMA,USER_LOCAL_STORAGE} from '../../constants/constants-paramentros'
import {authLogin} from '../../api/auth'
import {useNavigate} from 'react-router-dom'


export default function Login() {
    const navigate = useNavigate()

    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleLogin = async() => {
        const dataPOST = {
            user: user,
            password: password,
            parametro: PARAMETRO_SISTEMA
          }
          
          const dataPOSTEncriptado = {
            body: encriptar(dataPOST)
          }
      
          const { resEncriptado,status, message } = await authLogin(dataPOSTEncriptado)
          
          if(status){
            setLocalStorage(USER_LOCAL_STORAGE,resEncriptado)
            navigate('/admin/lista')
          } else{
            setErrorMessage(message)
          }         

    }

  return (
    <div className='container d-flex flex-wrap justify-content-center align-content-center' style={{height:"calc(100vh - 396px)"}}>
        <div className='m-3 p-5 bg-principal col-5' style={{borderRadius:"1rem",height:"max-content"}}>
            <div className='form-floating mt-2'>
                <input className='form-control' placeholder='Usuario' onChange={e=>setUser(e.target.value)}/>
                <label>Usuario</label>
            </div>
            <div className='form-floating mt-2'>
                <input type='password' className='form-control' placeholder='Contraseña' onChange={e=>setPassword(e.target.value)}/>
                <label>Contraseña</label>
            </div>
            <div className='mt-3 d-flex justify-content-center'>
                <button className='btn btn-light w-100' onClick={handleLogin}>Iniciar Sesion</button>
            </div>            
            <div className='mt-3 p-1 text-center' style={{color:"white"}}>{errorMessage}</div>
        </div>
        
    </div>
  )
}

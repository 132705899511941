import { api } from "./configApi";
import {desencriptar} from '../utils/funciones'

export const authLogin = async(dataPOST) => {
    try {
        const res = await api.post("api/admin/login", dataPOST)

         // res.status 201
         if (res.status === 201) {
            //Desencriptar el res
            const resDesencriptado = desencriptar(res.data.body)
            /* console.log(res,resDesencriptado) */
            return {
                status: resDesencriptado?.status,
                usuario: resDesencriptado,
                message: res?.data?.message,
                resEncriptado: res.data.body
            }
        }
        
        if (res.status === 200) {

            return {
                status: 0,
                usuario: null,
                message: res.data.message,
                resEncriptado: null
            };
        }
    } catch (error) {
        
        return {
            status: -1,
            usuario: null,
            message: "Error en el servidor",
            resEncriptado: null
        };
    }
}
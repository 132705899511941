import { useState, useEffect, useContext } from 'react'
import KRGlue from '@lyracom/embedded-form-glue'
import { server } from '../config/config'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { CarritoContext } from '../context/CarritoContext'
import { limpiarLocalStorage } from '../resources/funciones'
import Swal from 'sweetalert2';

export const PagoIziPay = () => {

    const { setCompraValida, vaciarCarrito } = useContext(CarritoContext)

    const logo = "/image/logo-envio.jpg"
    const [message, setMessage] = useState('')
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const location = useLocation()

    const formToken = location?.state?.token || ""
    const detalles = location?.state?.dataDetalles || ""
    const codSolicitud = location?.state?.codigo_solicitud || ""
    const dataComprobante = location?.state?.dataComprobante || {}

    const navigate = useNavigate()

    const irCarrito = () => navigate("/carrito")

    const  mensajeErrorPagoCincoIntentos = () => {
        Swal.fire({
            title: 'No se pudo procesar el pago',
            text: 'Verifica que los datos de la tarjeta sean correctos y que las compras por internet este activa',
            confirmButtonColor: 'rgb(5, 47, 62)'
        });
    }

    const mensajeErrorPago = (mensajeError) => {
        Swal.fire({
            title: 'No se pudo procesar el pago',
            text: `Por favor, verifica la información e inténtalo nuevamente. ${mensajeError}`,
            confirmButtonColor: 'rgb(5, 47, 62)'
        });
    }

    useEffect(() => {

        const script1 = document.createElement('script');
        script1.async = true;
        script1.defer = true;
        script1.src = 'https://static.micuentaweb.pe/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js';
        script1.setAttribute('kr-public-key', server.publicKeyIziPay);
        document.head.appendChild(script1);

        const script2 = document.createElement('script');
        script2.async = true;
        script2.defer = true;
        script2.src = 'https://static.micuentaweb.pe/static/js/krypton-client/V4.0/ext/classic.js';
        document.head.appendChild(script2);

        const link = document.createElement('link');
        link.async = true;
        link.defer = true;
        link.rel = 'stylesheet';
        link.href = 'https://static.micuentaweb.pe/static/js/krypton-client/V4.0/ext/classic-reset.css'
        document.head.appendChild(link);

        return () => {
            const script = document.querySelector('script[kr-public-key]')
            document.head.removeChild(script);
            // window.location.reload()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        async function setupPaymentForm() {
            const endpoint = "https://static.micuentaweb.pe"
            const publicKey = server.publicKeyIziPay
            try {

                const { KR } = await KRGlue.loadLibrary(
                    endpoint,
                    publicKey
                ) /* Load the remote library */

                await KR.setFormConfig({ /* set the minimal configuration */
                    formToken: formToken,
                    'kr-language': 'es-PE' /* to update initialization parameter */
                })

                const { result } = await KR.attachForm(
                    '#myPaymentForm'
                ) /* Attach a payment form  to myPaymentForm div*/


                await KR.onSubmit(paymentData => {

                    const dataPOST = {
                        SolCod: codSolicitud,
                        comprobante: dataComprobante,
                        izi: {
                            ...paymentData,
                            validar: true
                        }
                    }

                    axios
                        .post(server.serverUrl + "/api/solicitud/FinalizarSolicitud", dataPOST)
                        .then(response => {
                            if (response.status === 201) {
                                setCompraValida(true)
                                vaciarCarrito()
                                limpiarLocalStorage('cliente')
                                navigate("/final-compra",{
                                    replace: true 
                                })
                            }

                            if (response.status === 401) {
                                setMessage('Error al pagar')
                            }

                            if (response.status === 202) {
                                //me devuelve 202 en caso se haga 5 intentos de pago y todos fallidos
                                navigate("/compra",{
                                    replace: true
                                })
                                mensajeErrorPagoCincoIntentos()
                            }

                        }).catch(e => {
                            setMessage('Error en el servidor')
                        })
                    return false // Return false to prevent the redirection
                })

                await KR.onError(async (error) => {

                    const dataPOST = {
                        SolCod: codSolicitud,
                        PagTipComp: "B",
                        izi: {
                            ...error,
                            validar: false
                        }
                    }

                    axios
                        .post(server.serverUrl + "/api/solicitud/FinalizarSolicitud", dataPOST)
                        .then(response => {
                            if (response.data.status === 0) {
                                setMessage(response.data.message)
                                
                                if(response.status != 202){
                                    mensajeErrorPago(response.data.message)
                                }
                            }
                           
                        }).catch(error => {
                            setMessage('Error en el servidor')
                        })
                    return false // Return false to prevent the redirection
                })
    
                setShowPaymentForm(true); // Mostrar el formulario después de la configuración exitosa

                await KR.showForm(result.formId) /* show the payment form */
            } catch (error) {

            }
        }

        if (!!formToken) {
            setupPaymentForm()
        }

    }, [formToken])

    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'Script error.') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                )
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                )
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        })
    }, [])

    return (

        <>
            {/* Navbar de compra */}
            <nav className="navbar-compra">
                <div className="container contenedor-logo">
                    <Link className="navbar-brand col-4 m-1 logo-envio me-4" to="/inicio">
                        <img src={logo} alt="Logo Altos de Azpitia" />
                    </Link>
                    <h2 style={{ fontSize: "20px", marginBottom: "0" }}>Proceso de Compra</h2>
                </div>
            </nav>
            <div className="container">
                <div className="row">
                    {
                        (!!formToken) ? (
                            <>
                                <div className="col-md-6">
                                    <h3 className="mt-4 text-center">Resúmen de Envío</h3>
                                    <div className="border border-2 p-2 rounded-2" style={{ color: "rgba(54, 88, 101)" }}>

                                        <div> {detalles.SolNom + " " + detalles.SolApe}</div>
                                        <div> {detalles.SolEmail}</div>
                                        <div> {detalles.SolDire + ", " + detalles.SolCiud + ", " + detalles.SolProv + ", " + detalles.SolDep + ", " + detalles.SolPais}</div>
                                        <div> {detalles.SolTel}</div>
                                    </div>
                                    <h3 className="mt-4 text-center">Detalle de Comprobante</h3>
                                    <div className='border border-2 p-2 rounded-2' style={{ color: "rgba(54,88,101)"}}>
                                        <div>{dataComprobante.PagTipComp==='B'?'BOLETA':'FACTURA'}</div>
                                        {dataComprobante.PagTipComp==='B' && <div>{dataComprobante.PagTipId === 'D'?'DNI':'C. Extranjería'} - {dataComprobante.PagIdNum}</div>}
                                        {dataComprobante.PagTipComp==='F'&&  <div>RUC - {dataComprobante.PagRuc}</div>}
                                        <div>{dataComprobante.PagTipComp==='B'?dataComprobante.PagNom +' '+dataComprobante.PagApe:dataComprobante.PagRazon}</div>
                                        
                                    </div>
                                    <div className="mt-3 border border-2 p-2 rounded-2" style={{ color: "#555555" }}>
                                        Su pedido será enviado en un plazo de 3 a 5 días hábiles.
                                    </div>

                 
                                    <button className="btn btn-outline-success text-center mt-3" onClick={irCarrito}>
                                        Volver al carrito
                                    </button>
                                </div>
                                <div className="col-md-6">
                                    <h3 className="mt-4 text-center">Pago</h3>
                                    <div className="d-flex justify-content-center">
                                        <div className="form">
                                            <div className="container mb-5">
                                                <div id="myPaymentForm">
                                                    <div className="kr-smart-form" kr-card-form-expanded="true" ></div>
                                                </div>
                                                {/* <div data-test="payment-message">{message}</div> */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>
                        ) : (
                            <Link className="nav-link text-center mt-3" to="/compra">
                                Llenar los datos de envio
                            </Link>
                        )
                    }
                </div>

            </div>
        </>

    )
}

import { CONST_PISCO_ACHOLADO, CONST_PISCO_MOSTO_VERDE, CONST_PISCO_PURO } from "../constants/constants-tipo-piscos";
import { api } from "./configApi";

export const getProductos = async () => {
  try {

    const response = await api.get("api/dato_producto")
    const dataProductos = response.data.productos

    // const productos = dataProductos.map(dp => (
    //   {
    //     ...dp,
    //     ProdImg: `data:image/png;base64,${dp.ProdImg}`
    //   }
    // ))
    
    const listaPiscosPuros = dataProductos.filter(p => p.ProdTip == CONST_PISCO_PURO)
    const listaPiscosAcholados = dataProductos.filter(p => p.ProdTip == CONST_PISCO_ACHOLADO)
    const listaPiscosMostoVerde = dataProductos.filter(p => p.ProdTip == CONST_PISCO_MOSTO_VERDE)
    
    if (response.status === 201) {
      return {
        status: 1,
        productos: dataProductos,
        piscosPuros: listaPiscosPuros,
        piscosAcholados: listaPiscosAcholados,
        piscosMostoVerde: listaPiscosMostoVerde
      };
    } else {
      return {
        status: 0,
        productos: [],
        piscosPuros: [],
        piscosAcholados: [],
        piscosMostoVerde: []
      };
    }
  } catch (error) {
    return {
      status: 0,
      productos: [],
      piscosPuros: [],
      piscosAcholados: [],
      piscosMostoVerde: []
    }
  }
}

export const getUbigeo = async () => {
  try {

    const response = await api.get("api/dato_ubigeo")

    if (response.status === 201) {
      return {
        status: 1,
        ubigeo: response.data.ubigeo
      };
    } else {
      return {
        status: 0,
        ubigeo: []
      };
    }
  } catch (error) {
    return {
      status: 0,
      ubigeo: []
    }
  }
}

export const registrarSolicitud = async (dataPOST) => {
  try {

    const res = await api.post("api/solicitud/RegistrarSolicitud", dataPOST)

    if (res.status === 201) {
      return {
        status: 1,
        message: res.data.message,
        token: res.data.Token,
        codigo_solicitud: res.data["CODIGO DE SOLICITUD"]
      };
    } 

    if(res.status === 401){
      return {
        status: 0,
        message: res.message,
        token: "",
        codigo_solicitud: null
      }
    }
  } catch (error) {
    return {
      status: 0,
      message: 'Error en el servidor',
      token: "",
      codigo_solicitud: null
    }
  }
}

export const getPreciosActualizados = async(dataPOST) => {
  try {

    const res = await api.post("api/precio_producto", dataPOST)

    if (res.status === 201) {
      return {
        status: 1,
        preciosActualizados: res.data.lista_precio,
        message: res.data.message
      };
    } 

    if(res.status === 401){
      return {
        status: 0,
        preciosActualizados: [],
        message: res.data.message
      }
    }
  } catch (error) {
    return {
      status: -1,
      preciosActualizados: [],
      message: 'Error en el servidor',
    }
  }
}

export const getListaSolicitudes = async(dataPOST) => {
  try {
    const res = await api.post('api/solicitud/ListarSolicitud',dataPOST)
    
    if (res.status === 201) {
      return {
        status: 1,
        solicitudes: res.data.solicitudes,
        message: res.data.message
      };
    } 

  } catch (error) {
    return {
      status: -1,
      solicitudes: [],
      message: 'Error en el servidor',
    }
  }
}

export const entregarSolicitud = async (dataPOST) => {
    try {
      const res = await api.post('api/solicitud/EntregarSolicitud',dataPOST)
    
      if (res.status === 201) {
        return {
          status: 1,          
          message: res.data.message
        };
      } 
      
    } catch (error) {
      return {
        status: -1,
        solicitudes: [],
        message: 'Error en el servidor',
      }      
    }
  
}
import { useForm, Controller } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import { formatter, guardarLocalStorage, isValidEmail, obtenerLocalStorage } from "../resources/funciones"
import React, { useContext, useEffect, useState } from "react"
import { CarritoContext } from "../context/CarritoContext"
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { getUbigeo, registrarSolicitud } from "../api/azpitia"
import { ToastContainer, toast } from 'react-toastify';

import AutoInput from './common/AutoComplete'

const tema = "colored"

const notifyError = () => toast.error('Error en el servidor', {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: tema,
});

export const DatosEnvio = () => {

    const logo = "/image/logo-envio.jpg"

    const [listaUbigeo, setListaUbigeo] = useState([])
    const [listaUbigeoV2, setListaUbigeoV2] = useState([])
    const [loading, setLoading] = useState(false)

    const datosLS = obtenerLocalStorage('cliente')

    const { register, handleSubmit, formState: { errors }, reset, control, watch, setValue } = useForm({
        defaultValues: {
            email: datosLS?.email || "",
            nombre: datosLS?.nombre || "",
            apellido: datosLS?.apellido || "",
            telefono: datosLS?.telefono || "",
            pais: datosLS?.pais || "Peru",
            ciudad: datosLS?.ciudad || "",
            direccion: datosLS?.direccion || "",
            cod_postal: datosLS?.cod_postal || "",
            depProvDis: "",
            comprobante: datosLS?.comprobante || { tipo: "", documento: "", nroDocumento: "", nombre: "", apellido: "", ruc: "", razon: "" },
        }
    })

    /* useEffect(() => {
        console.log(watch())
    }, [watch()]); */
    const { carrito, totalCarrito } = useContext(CarritoContext)
    const navigate = useNavigate()

    const onSubmitDetalles = async (data) => {
        console.log(data)
        setLoading(true)

        guardarLocalStorage(data, 'cliente')

        const dataDepProvDis = data?.depProvDis || ""

        const listaProductos = carrito?.map((p) => (
            {
                ProdCod: p.ProdCod,
                Cantidad: p.Cantidad,
                ProdPack: p.ProdPack,
                Detalle: p.detalle
            }
        ))
        let departamento = "";
        let provincia = "";
        let distrito = "";

        if (dataDepProvDis != "") {
            departamento = dataDepProvDis.split("/")[0]
            provincia = dataDepProvDis.split("/")[1]
            distrito = dataDepProvDis.split("/")[2]
        }

        const dataDetalles = {
            SolEmail: data.email.trim(),
            SolNom: data.nombre.trim().toUpperCase(),
            SolApe: data.apellido.trim().toUpperCase(),
            SolTel: data.telefono.trim(),
            SolPais: data.pais.trim().toUpperCase(),
            SolDep: departamento.toUpperCase(),
            SolProv: provincia.toUpperCase(),
            SolCiud: (distrito != "" ? distrito.toUpperCase() : data.ciudad.trim().toUpperCase()),
            SolDire: data.direccion.trim().toUpperCase(),
            SolCodPost: data.cod_postal.trim(),
            list: listaProductos || []
        }

        const dataComprobante = {
            PagTipComp: data.comprobante.tipo,
            PagTipId: data.comprobante.documento,
            PagIdNum: data.comprobante.nroDocumento,
            PagNom: data.comprobante.nombre.trim().toUpperCase(),
            PagApe: data.comprobante.apellido.trim().toUpperCase(),
            PagRuc: data.comprobante.ruc,
            PagRazon: data.comprobante.razon.trim().toUpperCase()
        }

        const { status, message, token, codigo_solicitud } = await registrarSolicitud(dataDetalles)

        if (status == 1) {
            navigate("/pagar-izipay", {
                state: {
                    token,
                    dataDetalles,
                    codigo_solicitud,
                    dataComprobante
                }
            })
        } else {
            notifyError()
            setLoading(false)
        }


    }

    const handleEmailValidation = email => {
        const isValid = isValidEmail(email);
        return isValid;
    };

    const handleIdNumberValidation = (value) => {
        let isValid = true
        if (watch('comprobante').tipo === 'B') {
            if (watch('comprobante').documento === 'D') {
                isValid = !isNaN(value) && value.length === 8
            } else if (watch('comprobante').documento === 'CE') {
                isValid = !isNaN(value) && value.length === 9
            }
        } else {
            isValid = !isNaN(value) && value.length === 11
        }
        return isValid
    }

    const obtenerListaUbigeo = async () => {
        const { status, ubigeo } = await getUbigeo()

        if (status == 1) {
            setListaUbigeo(ubigeo)
            let ubigeoV2 = []
            ubigeo.forEach(item => {
                ubigeoV2.push(item.tipo)
            })
            setListaUbigeoV2(ubigeoV2)
        } else {
            setListaUbigeo([])
        }
    }

    useEffect(() => {
        obtenerListaUbigeo();
    }, []);



    return (
        <>
            {/* Navbar de compra */}
            <nav className="navbar-compra">
                <div className="container contenedor-logo">
                    <Link className="navbar-brand col-4 m-1 logo-envio me-4" to="/inicio">
                        <img src={logo} alt="Logo Altos de Azpitia" />
                    </Link>
                    <h2 style={{ fontSize: "20px", marginBottom: "0" }}>Proceso de Compra</h2>
                </div>
            </nav>

            {/* Formulario de compra */}
            <div className="container">
                <div className="row carrito-contenedor">
                    <div className="col-lg-8 compra">
                        <form onSubmit={handleSubmit(onSubmitDetalles)} className="p-1">
                            <div className="compra-titulo">
                                <h2 className="mt-4">Datos de envio</h2>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-floating">
                                        <input
                                            {...register("email", { required: true, validate: handleEmailValidation })}
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                        />
                                        <label>Email para confirmar el pedido <span style={{ color: "red" }}>*</span></label>
                                        {errors.email && errors.email.type === "required" && <p role="alert" className="error">Ingrese su email</p>}
                                        {errors.email && errors.email.type === "validate" && <p role="alert" className="error">Email incorrecto</p>}
                                    </div>
                                </div>                                                                
                            </div>

                            <div className="row mt-2">
                                <div className="col-6">
                                    <div className="form-floating">
                                        <input
                                            {...register("nombre", { required: true })}
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                        />
                                        <label>Nombres <span style={{ color: "red" }}>*</span></label>
                                        {errors.nombre && errors.nombre.type === "required" && <p role="alert" className="error">Ingrese su nombre</p>}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-floating">
                                        <input
                                            {...register("apellido", { required: true })}
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                        />
                                        <label>Apellidos <span style={{ color: "red" }}>*</span></label>
                                        {errors.apellido && errors.apellido.type === "required" && <p role="alert" className="error">Ingrese su apellido</p>}
                                    </div>
                                </div>


                            </div>
                            <div className="row mt-2">
                                <div className="col-6">
                                    <div className="form-floating">
                                        <select {...register("pais", { required: true })} className="form-select">
                                            <option value="Peru">Perú</option>
                                        </select>
                                        <label>País <span style={{ color: "red" }}>*</span></label>
                                        {errors.pais && errors.pais.type === "required" && <p role="alert" className="error">Ingrese país</p>}
                                    </div>
                                </div>
                                {
                                    watch("pais") == "Peru" ? (
                                        <div className="col-6">
                                            {/* <Controller
                                                name="depProvDis"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Autocomplete
                                                        classes={{ inputRoot: 'form-floating', input: 'form-select MuiAutoCustom pt-0 pb-0', }}
                                                        disablePortal
                                                        size="small"
                                                        id="combo-box-demo"
                                                        options={listaUbigeo}
                                                        getOptionLabel={(option) => option.tipo}
                                                        // getOptionValue={(option) => option.id} // Obtener el valor del 'id' del distrito
                                                        renderInput={(params) => <TextField {...params} placeholder="Distrito *" />}
                                                        onChange={(e, newValue) => field.onChange(newValue)} // Actualizar el valor del campo "distrito" cuando se seleccione una opción
                                                    />
                                                )}
                                            /> */}
                                            <AutoInput {...register('depProvDis', { required: true })} data={listaUbigeoV2} placeholder='Distrito' required={true} saveData={(value) => { setValue('depProvDis', value) }} />
                                            {errors.depProvDis && errors.depProvDis.type === "required" && <p role="alert" className="error">Ingrese Distrito</p>}

                                        </div>


                                    ) : (
                                        <div className="col-6">
                                            <div className="form-floating">
                                                <input
                                                    {...register("ciudad", { required: true })}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                <label>Ciudad <span style={{ color: "red" }}>*</span></label>
                                                {errors.ciudad && errors.ciudad.type === "required" && <p role="alert" className="error">Ingrese ciudad</p>}
                                            </div>
                                        </div>
                                    )
                                }
                            </div>

                            <div className="row mt-2">
                                <div className="col-12">
                                    <div className="form-floating">
                                        <input
                                            {...register("direccion", { required: true })}
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                        />
                                        <label>Dirección <span style={{ color: "red" }}>*</span></label>
                                        {errors.direccion && errors.direccion.type === "required" && <p role="alert" className="error">Ingrese su dirección</p>}
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-6">
                                    <div className="form-floating">
                                        <input
                                            {...register("telefono", { required: true })}
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                        />
                                        <label>Teléfono <span style={{ color: "red" }}>*</span></label>
                                        {errors.telefono && errors.telefono.type === "required" && <p role="alert" className="error">Ingrese su teléfono</p>}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-floating">
                                        <input
                                            {...register("cod_postal")}
                                            type="text"
                                            className="form-control"
                                            placeholder="Codigo Postal"
                                        />
                                        <label >Código postal</label>
                                    </div>
                                </div>
                            </div>

                            <div className="compra-titulo">
                                <h2 className="mt-4">Datos de comprobante</h2>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="form-floating">

                                        <select className="form-select" {...register("comprobante.tipo", { required: true })}>
                                            <option value="">Seleccionar Comprobante</option>
                                            <option value="B">Boleta</option>
                                            <option value="F">Factura</option>
                                        </select>
                                        <label className="form-label">Tipo de Comprobante <span style={{ color: "red" }}>*</span></label>
                                        {errors.comprobante?.tipo && errors.comprobante?.tipo?.type === "required" && <p role="alert" className="error">Seleccione Tipo de Comprobante</p>}
                                    </div>
                                </div>
                            </div>


                            {watch("comprobante.tipo") === 'B' && <>
                                <div className="row mt-2">
                                    <div className="col-6">
                                        <div className="form-floating">
                                            <select className="form-select" {...register("comprobante.documento", { required: true })}>
                                                <option value="">Seleccionar</option>
                                                <option value="D">DNI</option>
                                                <option value="CE">C. Extrajeria</option>
                                            </select>
                                            <label className="form-label">Tipo Documento <span style={{ color: "red" }}>*</span></label>
                                            {errors.comprobante?.documento && errors?.comprobante?.documento?.type === "required" && <p role="alert" className="error">Seleccione su Tipo de Documento</p>}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-floating">
                                            <input
                                                {...register("comprobante.nroDocumento", { required: true, validate: handleIdNumberValidation })}
                                                type="text"
                                                className="form-control form-control-sm input-form"
                                            />
                                            <label className="form-label">Número de Documento <span style={{ color: "red" }}>*</span></label>
                                            {errors.comprobante?.nroDocumento && errors?.comprobante?.nroDocumento?.type === "required" && <p role="alert" className="error">Ingrese su Nro. de Documento</p>}
                                            {errors.comprobante?.nroDocumento && errors?.comprobante?.nroDocumento?.type === "validate" && <p role="alert" className="error">Número de Documento Invalido</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-6">
                                        <div className="form-floating">
                                            <input
                                                {...register("comprobante.nombre", { required: true })}
                                                type="text"
                                                className="form-control form-control-sm input-form"
                                            />
                                            <label className="form-label">Nombres <span style={{ color: "red" }}>*</span></label>
                                            {errors.comprobante?.nombre && errors?.comprobante?.nombre?.type === "required" && <p role="alert" className="error">Ingrese su Nombre</p>}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-floating">
                                            <input
                                                {...register("comprobante.apellido", { required: true })}
                                                type="text"
                                                className="form-control form-control-sm input-form"
                                            />
                                            <label className="form-label">Apellidos <span style={{ color: "red" }}>*</span></label>
                                            {errors.comprobante?.apellido && errors?.comprobante?.apellido?.type === "required" && <p role="alert" className="error">Ingrese su Apellido</p>}
                                        </div>
                                    </div>
                                </div>



                            </>}

                            {watch("comprobante.tipo") === "F" && <>
                                <div className="row mt-2">
                                    <div className="col-6">
                                        <div className="form-floating">
                                            <input
                                                {...register("comprobante.ruc", { required: true, validate: handleIdNumberValidation })}
                                                type="text"
                                                className="form-control form-control-sm input-form"
                                            />
                                            <label className="form-label">Numero de RUC <span style={{ color: "red" }}>*</span></label>
                                            {errors.comprobante?.ruc && errors?.comprobante?.ruc?.type === "required" && <p role="alert" className="error">Ingrese su RUC</p>}
                                            {errors.comprobante?.ruc && errors?.comprobante?.ruc?.type === "validate" && <p role="alert" className="error">RUC incorrecto</p>}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-floating">
                                            <input
                                                {...register("comprobante.razon", { required: true })}
                                                type="text"
                                                className="form-control form-control-sm input-form"
                                            />
                                            <label className="form-label">Razón Social <span style={{ color: "red" }}>*</span></label>
                                            {errors.comprobante?.razon && errors?.comprobante?.razon?.type === "required" && <p role="alert" className="error">Ingrese su Razón Social</p>}
                                        </div>
                                    </div>
                                </div>
                            </>}

                        </form>
                        <div className="d-flex justify-content-center mt-3 mb-4">
                            <button
                                type="button submit"
                                onClick={handleSubmit(onSubmitDetalles)}
                                className="btn bg-principal w-100 text-white"
                                style={{ cursor: 'pointer' }}
                                disabled={carrito?.length > 0 ? false : true}
                            >
                                Continuar&nbsp;&nbsp;
                                {
                                    loading && (
                                        <div className="spinner-border text-light spinner-border-sm" role="status">
                                            <span className="visually-hidden" >Loading...</span>
                                        </div>
                                    )
                                }

                            </button>
                        </div>
                    </div>
                    <div className="col-lg-4 resumen-pedido">
                        <div className="compra-titulo">
                            <h3 className="mt-4">Resumen del pedido</h3>
                            <Link to={`/carrito`} className="mt-4"><h3>Editar carrito</h3></Link>
                        </div>

                        <div className="compra-carrito">
                            {
                                carrito?.length > 0 ? (
                                    <>
                                        <div className="cart-content">
                                            {
                                                carrito.length > 0 && carrito.map((c) => (
                                                    <div className="cart-box" key={c?.ProdCod}>
                                                        <div className="bg-image-cart border border-1 rounded-2">
                                                            <img src={c?.ProdImg} alt="..." className="cart-img" />
                                                        </div>

                                                        <div className="detail-box">
                                                            <div className="cart-product-title">{c?.ProdNom}</div>
                                                            <div className="cart-price">
                                                                {
                                                                    (!c?.ProdPrecD) ? (
                                                                        <strong style={{ color: "#052F3E" }}>{formatter.format(c?.ProdPrecN)}</strong>
                                                                    ) : (
                                                                        <>
                                                                            <s style={{ fontSize: "14px", color: "rgb(128,128,128)" }}>{formatter.format(c?.ProdPrecN)}</s>
                                                                            <strong className="ms-2" style={{ color: "#052F3E" }}>{formatter.format(c?.ProdPrecD)}</strong>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className="numero">Cantidad: <span style={{ color: "#052F3E" }}>{c?.Cantidad}</span></div>

                                                        </div>
                                                    </div>
                                                ))
                                            }

                                        </div>

                                        <div className="total">
                                            <div className="total-title">Total</div>
                                            <div className="total-price">{totalCarrito()}</div>
                                        </div>
                                    </>
                                ) : (
                                    <Link className="nav-link text-center mt-5" to="/tienda">
                                        Añadir productos al carrito
                                    </Link>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme={tema}
            />
        </>
    )
}

import React,{useEffect,useState} from 'react'
import {getListaSolicitudes,entregarSolicitud} from '../../api/azpitia'
import {getLocalStorage, removeLocalStorage} from '../../utils/funciones'
import {USER_LOCAL_STORAGE} from '../../constants/constants-paramentros'


import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'

import Pagination from '../common/Pagination'

const hoy = new Date()
hoy.setUTCHours(hoy.getUTCHours() - 5)
const hoyFormatted = hoy.toISOString().slice(0, 10);

let semPa = new Date()
semPa.setUTCDate(semPa.getUTCDate() - 7)
semPa.setUTCHours(semPa.getUTCHours() -5)
const semPaFormatted = semPa.toISOString().slice(0, 10)


const defaultFilter = {fechaInicio:semPaFormatted,fechaFin:hoyFormatted,texto:"",estado:''}
export default function ListaPedidos() {
  const navigate = useNavigate()

  const [filter, setFilter] = useState(defaultFilter);
  const [dataSol, setDataSol] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);

  const updateFilter = (update) => {
    let newData = filter
    newData = {...filter,...update} 
    setFilter(newData)
  }

  const handleSearch = async() => {
    const {solicitudes} = await getListaSolicitudes(filter)    
    setDataSol(solicitudes)
    
  }

  const handleCloseSession = () =>{
    removeLocalStorage(USER_LOCAL_STORAGE)
    navigate('/inicio')

  }

  const handleShowMore = (data) => {    
    let rows = ''
    const direccion = `${data.SolDire}, ${data.SolCiud}, ${data.SolProv}, ${data.SolDep}`
    data.SolDetalle.forEach(item=>{
      if(item.ProdDet){
        let subrow = ''
        item.ProdDet.forEach(subItem =>{
          subrow += 
            `
            <div class='col-12'>${subItem.ProdNom}(${subItem.ProdCant})</div>
            
            `
        })
        rows += 
        `
        <tr>
          <td>
            <div class='row text-start' style='font-size:1.25rem;'><div class='col-12'>${item.ProdNom}</div></div>
            <div class='row text-start' style='color:gray; font-size:1rem'>${subrow}</div>
          </td>
          <td class='text-center'>${item.ProdCant}</td>
        </tr>
        `
      }else{
        rows += `<tr><td><div class='row text-start'style='font-size:1.25rem;'><div class='col-12'>${item.ProdNom}</div></div></td><td>${item.ProdCant}</td></tr>`
      }
    })

    function createGoogleMapsLink(address) {
      const baseUrl = "https://www.google.com/maps/search/?api=1&query=";
      const formattedAddress = encodeURIComponent(address);
      return `${baseUrl}${formattedAddress}`;
    }               
    
        
    Swal.fire({
      title:`Pedido ${data.SolCod}`,
      width:'50vw',
      confirmButtonText:"Aceptar",
      showCloseButton:true,
      html:
        `
        <div class='container mt-4 mb-1'>
          ${data.SolComp? 
            `
          <div class='row'>
            <div class='text-start mb-2' style='color:#052f3e;'>Datos del Comprobante:</div>          
          </div>
          <div class='row'>
            <div class='col-9 row'>
              <div class='col-3 text-start'>Tipo:</div>
              <div class='col-9 text-start'>${data.SolComp.PagTipComp==='B'?'BOLETA':'FACTURA'}</div>
              <div class='col-3 text-start'>Nombre:</div>
              <div class='col-9 text-start'>${data.SolComp.PagNom}</div>
              <div class='col-3 text-start'>Documento:</div>
              <div class='col-9 text-start'>${data.SolComp.PagTipId==='D'?'DNI':data.SolComp.PagTipId==='CE'?'C. Extranjería':data.SolComp.PagTipId}</div>
              <div class='col-3 text-start'>Número:</div>
              <div class='col-9 text-start'>${data.SolComp.PagIdNum}</div>
              <div class='col-3 text-start'>Monto Pagado:</div>
              <div class='col-9 text-start'>${data.SolComp.PagMont} ${data.SolComp.PagMone}</div>
            </div>
          </div>
          `:""
          }          
          <div class='row mt-4'>            
            <div class='col-3 text-start mb-2' style='color:#052f3e;'>Detalle de Pedido:</div>
          </div>
          <div class='row'>
            <div class='col-12 d-flex justify-content-center'>
              <table class='table' style='font-size:0.9rem;'>
                <thead style='background:#052f3e;color:white;'>
                  <tr>
                    <th class='col-10'>Ítem</th>
                    <th class='col-2 text-center'>Cantidad</th>
                  </tr>
                </thead>
                <tbody>
                  ${rows}
                </tbody>
              </table>
            </div>
          </div>
          <div class='row mt-4'>
            <div class='col-3 text-start align-content-center' style='color:#052f3e;'>Dirección de Entrega: </div>
            <div class='col-9 row'>
              <div class='col-11 text-start align-content-center'><a target='_blank' href='${createGoogleMapsLink(direccion)}' >${direccion}</a></div>
              <div class='col-1'>
                <a class='btn btn-light' target='_blank' href='${createGoogleMapsLink(direccion)}'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
                  </svg>
                </a>
              </div>
            </div>            
          </div>
          <div class='row mt-4'>
            <div class='col-3 text-start' style='color:#052f3e;'>Datos de Contacto: </div>
            <div class='col-9 row'>
              <div class='col-3 text-start'>Teléfono:</div>
              <div class='col-9 text-start'>${data.SolTel}</div>
              <div class='col-3 text-start'>Correo:</div>
              <div class='col-9 text-start'>${data.SolEmail}</div>            
            </div>
          </div>
        </div>
        `
    })
  }

  const handleEntregarPedido = (id) => {
    Swal.fire({
      title:'Actualizar Solicitud',
      icon:'warning',
      text:`El Pedido ${id} se actualizará a Entregado.`,
      confirmButtonText:'Actualizar'
    }).then(async result => {
        if(result.isConfirmed){
          const {message} = await entregarSolicitud({solId:id})
          handleSearch()
          Swal.fire({
            title:message
          })
        }      
    })
  }

  function writeState(state){
    switch (state){
      case 'P':
        return 'Pagado'
      case 'PP':
        return 'Pediente de Pago'
      case 'E':
        return 'Entregado'
      default:
        return 'Estado Desconocido'       

    }
  }


  useEffect(() => {
    if(!getLocalStorage(USER_LOCAL_STORAGE)){
      navigate('/admin/login')
    }else{
      handleSearch()
    }
  }, []);

  

  return (
    <div className='container-fluid'>
      <div className='text-end' style={{cursor:"pointer"}} onClick={handleCloseSession}>Cerrar Sesion</div>
      <div className='container mt-2'>
        <h2>Lista de Pedidos</h2>
        <div className='border rounded-2 mt-5 mb-5 p-4'>
            <div className='row'>
              <div className='col-2'>
                <label htmlFor='fechaI'>Fecha de Inicio</label>
                <input id='fechaI' type='date' value={filter.fechaInicio} onChange={e=>updateFilter({fechaInicio:e.target.value})} className='form-control'/>
              </div>
              <div className='col-2'>
                <label htmlFor='fechaF'>Fecha de Fin</label>
                <input id='fechaF' type='date' value={filter.fechaFin} onChange={e=>updateFilter({fechaFin:e.target.value})} className='form-control'/>
              </div>
              <div className='col-2'>
                <label>Estado</label>
                <select className='form-select' value={filter.estado} onChange={e=>updateFilter({estado:e.target.value})}>
                  <option value="">Todos</option>
                  <option value="PP">Pendiente de Pago</option>
                  <option value="P">Pagado</option>
                  <option value="E">Entregado</option>
                  
                </select>
              </div>
              <div className='col-4'>
                <label>Cliente / Nro. Pedido</label>
                <input className='form-control' value={filter.texto} onChange={e=>updateFilter({texto:e.target.value})} placeholder='Escriba aquí para buscar...'></input>
              </div>   
              <div className='col-2 align-content-end'>
                <button className='btn btn-primary' onClick={handleSearch}>Buscar</button>
              </div>
            </div>            
        </div>
        <table className='table'>
          <thead style={{background:"#052f3e",color:"white"}}>
            <tr>
              <th className='text-center'>Pedido</th>
              <th className='text-center'>Fecha Pedido</th>
              <th className='text-center'>Cliente</th>
              <th className='text-center'>Estado</th>
              {/* <th className='text-center'>Dirección de Entrega</th> */}
              <th className='text-center'></th>
            </tr>
          </thead>
          <tbody>
            {
              paginatedData.map(sol=>(
                <tr key={sol.SolCod}>
                  <td className='text-center align-content-center'>{sol.SolCod}</td>
                  <td className='text-center align-content-center'>{sol.SolFchCre}</td>
                  <td className='text-center align-content-center'>{sol.SolNomCom}</td>
                  <td className='text-center align-content-center'>{writeState(sol.SolEst)}</td>
                  {/* <td className='text-center'>{`${sol.SolDire}, ${sol.SolCiud}, ${sol.SolProv}, ${sol.SolDep}`}</td> */}
                  <td className='text-center align-content-center'>
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          Aciones
                        </button>
                        <ul className="dropdown-menu">
                          <li><div style={{cursor:"pointer"}} className="dropdown-item" onClick={()=>handleShowMore(sol)}>Ver Detalles</div></li>
                          {sol.SolEst === 'P' && <li><div style={{cursor:"pointer"}} className="dropdown-item" onClick={()=>handleEntregarPedido(sol.SolCod)}>Pedido Entregado</div></li>}                          
                        </ul>
                    </div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <Pagination data={dataSol} setPageData={setPaginatedData}/>
      </div>
      
    </div>
  )
}

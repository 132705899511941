import { useNavigate } from "react-router-dom"
import { useMobileDetection } from "../hook/useMobileDetection"

export const MensajeMayorEdad = () => {

    const isMobile = useMobileDetection()

    const navigate = useNavigate()

    const inicioApp = () => {
        navigate('/inicio')
    }

    const salir = () => {
        window.close()
    }

    return (
        <div className="bg-principal vh-100 d-flex justify-content-center">
            
            <div className={`bg-pagina ${isMobile ? "p-3" : "p-5"} m-auto mx-3 rounded-2`}>                    
                <h1 className="text-center" style={{color: "#324158"}}>¿Eres mayor de edad?</h1>
                <div className="mt-5 mb-2">
                    <button type="button" className="btn bg-principal w-100 text-white mx-auto m-1" onClick={inicioApp}>Sí, soy mayor de edad en mi país de residencia.</button>
                    <button type="button" className="btn bg-principal w-100 text-white mx-auto m-1" onClick={salir}>No, deseo salir de esta página.</button>
                </div>
                <div className="mt-3">
                    <div className="text-center" style={{ color: "rgb(128,128,128)" }}>Tienes que ser mayor de edad para navegar en nuestra página web</div>                        
                </div>
            </div>
            
            
            <div className="w-100 mb-2" style={{position:"absolute",bottom:"0", color: "white"}}>
                <div className="text-center">*TOMAR BEBIDAS ALCOHÓLICAS EN EXCESO ES DAÑINO*</div>
            </div>
        </div>
    )
}

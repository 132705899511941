import { useContext, useEffect } from "react"
import { CarritoContext } from "../context/CarritoContext"
import { Link, useNavigate } from "react-router-dom"

export const FinalCompra = () => {

  const { compraValida } = useContext(CarritoContext)
  const navigate = useNavigate()

  useEffect(() => {
    if(!compraValida){
      navigate("/inicio")
    }
  }, [compraValida])
  

  return (    
      <div className="container d-flex justify-content-center" style={{ height: "calc(100vh - 396px)" }}>
        {
          compraValida && 
            <div className="p-5 d-flex flex-column justify-content-between" style={{borderImage:'url("/image/lazo.png") 48 / 48px / 0 round'}}>
                  <h1 className="text-center" style={{color:"#052f3e"}}>¡Compra completada con éxito!</h1>
                  
                  <div className="text-center" style={{fontSize:"1.4rem",color: "#555555"}}>
                    <p>Gracias por elegir nuestros piscos, te enviaremos un correo con los datos de tu compra.</p>
                    <p>Si tienes alguna consulta sobre tu pedido, no dudes en comunicarte al correo <a href="mailto:pedidos@altosdeazpitia.com">pedidos@altosdeazpitia.com</a></p>
                  </div>

                  <div className="d-grid justify-content-center text-center" style={{fontSize:"1.2rem",color: "#555555"}}>
                    <p>¡Salud y gracias por confiar en nosotros! 🍹🎉</p>
                    <p>Equipo de Altos de Azpitia</p>
                  </div>
                  
            </div>          
        }
      </div >      
  )
}

import { useEffect, useState,useContext } from "react"
import Swal from 'sweetalert2'

//Contexts
import { CarritoContext } from "./CarritoContext"
import { ProductoContext } from '../context/ProductoContext'

//Utils
import { formatter } from "../resources/funciones";

export const CarritoProvider = ({ children }) => {

    const { listaProductos } = useContext(ProductoContext)

    const [carrito, setCarrito] = useState([])

    const [mostrarCarrito, setMostrarCarrito] = useState(false);

    const [compraValida, setCompraValida] = useState(false)

    const abrirCarrito = () => {
        setMostrarCarrito(true)
        // setIsNavOpen(false)
    }

    const cerrarCarrito = () => {
        setMostrarCarrito(false)
    }
    /* ----------------- Codigo Agregado ----------------------*/
    const aniadirPack = (packAniadido,contador) => {
        const listaDisponible = listaProductos.filter(prods => prods.ProdTip === packAniadido.ProdTip && !prods.ProdPack)
        const cantMax = packAniadido.ProdPackUni * contador

        

        function gethtml (){
            let html = ''
            listaDisponible.forEach((prod,index)=>{
                html +=
                `
                <tr class='m-2'>
                    <td class='col-7 col-md-9 align-middle text-start' style='color:white;'><span class='pack-product-name'>${prod.ProdNom}<span></td>
                    <td class='col-5 col-md-3'>
                        <div class='input-group mb-2'>
                            <button class='btn btn-secondary decrement-btn' type='button' data-index='${index}'>-</button>
                            <input class='form-control product-input' readonly  value='0' min='0'data-index='${index}' data-prodcod='${prod.ProdCod}'>
                            <button class='btn btn-secondary increment-btn' type='button' data-index='${index}'>+</button>
                        </div>
                    </td>                        
                </tr>
                ` 
            })
            return html
        }
        
        Swal.fire({
            title:`<span style='color:white;'>${packAniadido.ProdNom}</span>`,        
            width:'max-content',
            background:'#052f3e',
            confirmButtonText:'Continuar',
            html:
            `
            <div class='container'>
                <div class='text-start mb-3' style='color:lightgray'>Por favor elija el(los) producto(s) que desea (máximo ${cantMax}):</div>
                <table>
                    <tbody>
                        ${gethtml()}
                    </tbody>
                </table>
            </div>
            `,
            didRender: () => {
                // Capturamos todos los elementos relacionados
                const inputs = document.querySelectorAll('.product-input');
                const incrementButtons = document.querySelectorAll('.increment-btn');
                const decrementButtons = document.querySelectorAll('.decrement-btn');

                let totalSum = 0;

                // Función para actualizar el valor total
                const updateTotalSum = () => {
                    totalSum = 0;
                    inputs.forEach(input => {
                        totalSum += parseInt(input.value) || 0;
                    });
                };

                // Manejador de los botones "+"
                incrementButtons.forEach(button => {
                    button.addEventListener('click', (e) => {
                        const index = e.target.getAttribute('data-index');
                        const input = document.querySelector(`input[data-index="${index}"]`);
                        let currentValue = parseInt(input.value);

                        // Verificar si la suma total supera el límite
                        if (totalSum < cantMax) {
                            input.value = currentValue + 1;
                            updateTotalSum();
                        }
                    });
                });

                // Manejador de los botones "-"
                decrementButtons.forEach(button => {
                    button.addEventListener('click', (e) => {
                        const index = e.target.getAttribute('data-index');
                        const input = document.querySelector(`input[data-index="${index}"]`);
                        let currentValue = parseInt(input.value);

                        if (currentValue > 0) {
                            input.value = currentValue - 1;
                            updateTotalSum();                            
                        }
                    });
                });
            },
            preConfirm: ()=>{
                let totalSum = 0;
                const inputs = document.querySelectorAll('.product-input');
                const detalle = []

                inputs.forEach(input => {
                    totalSum += parseInt(input.value) || 0;
                    if(input.value>0){
                        detalle.push({ProdCod:input.getAttribute('data-prodcod'),Cantidad:parseInt(input.value)})
                    }
                });

                if(totalSum < cantMax){
                    Swal.showValidationMessage(`Falta escoger ${cantMax - totalSum} producto(s).`)
                }else{
                    return detalle
                }
            }
        }).then(result => {
            if(result.isConfirmed){
                aniadirProducto({...packAniadido,detalle:result.value},contador)
            }
        })

    }
    /*------------------------------------------------------*/

    const aniadirProducto = (productoAniadido, contador) => {

        const carritoLS = JSON.parse(localStorage.getItem('carrito')) || [];

        const existeProductoCarrito = carritoLS?.find(c => c?.ProdCod == productoAniadido?.ProdCod)

        let dataGuardar
        
        if(existeProductoCarrito) {
            // actualiza la cantidad de productos en caso ya haya sido agregado un producto que ya ha sido agregado antes al carrito
           
           
          
            let cantidadActualizada = existeProductoCarrito?.Cantidad + contador 
            const carritoActualizado = carritoLS?.map( (c)=>{

                if(c?.ProdCod === productoAniadido?.ProdCod){
                    let nuevoDetalle
                    
                    if(c.detalle){
                        nuevoDetalle = c.detalle.concat(productoAniadido.detalle)
                        nuevoDetalle = Object.values(
                            nuevoDetalle.reduce((acc, item) => {
                                // Si el ProdCod ya existe en el objeto acumulador, suma la cantidad
                                if (acc[item.ProdCod]) {
                                  acc[item.ProdCod].Cantidad += parseInt(item.Cantidad);
                                } else {
                                  // Si no existe, inicializa la cantidad
                                  acc[item.ProdCod] = {
                                    ProdCod: item.ProdCod,
                                    Cantidad: item.Cantidad
                                  }
                                }
                                return acc;
                              }, {})
                        )
                    }
                    return {
                        ...c,
                        Cantidad: cantidadActualizada,
                        ProdPrecN: productoAniadido?.ProdPrecN,
                        detalle:nuevoDetalle
                    }
                }
                return c
            })

            setCarrito(carritoActualizado)
            dataGuardar = carritoActualizado

        }else{
            dataGuardar = [
                ...carritoLS,
                {
                    ...productoAniadido,
                    Cantidad: contador,
                    ProdPrecN: productoAniadido?.ProdPrecN
                }
            ]

            setCarrito(
                [
                    ...carritoLS,
                    {
                        ...productoAniadido,
                        Cantidad: contador,
                        ProdPrecN: productoAniadido?.ProdPrecN
                    }
                ]
            )
    
        }

        localStorage.setItem('carrito', JSON.stringify(dataGuardar));
        abrirCarrito(true)
    }

    const eliminarProducto = (e, id) => {

        const carritoLS = JSON.parse(localStorage.getItem('carrito')) || [];
        const carritoActual = carritoLS?.filter(c => c?.ProdCod != id)
        localStorage.setItem('carrito', JSON.stringify(carritoActual));
        setCarrito(carritoActual)
    }

    const aniadirCantidad = (id) => {

        const carritoLS = JSON.parse(localStorage.getItem('carrito')) || [];
        const carritoActualizado = carritoLS?.map((c) => {

            if (c?.ProdCod == id) {

                return {
                    ...c,
                    Cantidad: c?.Cantidad + 1
                }
            }
            return c
        })

        setCarrito(carritoActualizado)
        localStorage.setItem('carrito', JSON.stringify(carritoActualizado));
    }

    const disminuirCantidad = (id) => {

        const carritoLS = JSON.parse(localStorage.getItem('carrito')) || [];
        const cantidadProductoEncontrado = carrito?.find(c => c?.ProdCod == id)?.Cantidad

        if (cantidadProductoEncontrado <= 1) {
            // La cantidad se queda como minimo a 1
        } else {

            const carritoActualizado = carritoLS?.map((c) => {

                if (c?.ProdCod == id) {

                    return {
                        ...c,
                        Cantidad: c?.Cantidad - 1
                    }
                }
                return c
            })
            setCarrito(carritoActualizado)
            localStorage.setItem('carrito', JSON.stringify(carritoActualizado));
        }
    }


    const vaciarCarrito = () => {
        localStorage.setItem('carrito', JSON.stringify([]));
        setCarrito([])
    }

       
    const totalCarrito = () => {
        const carritoLS = JSON.parse(localStorage.getItem('carrito')) || [];

        const total = carritoLS.reduce((total, producto) => {
            const precio = producto.ProdPrecD ? parseFloat(producto.ProdPrecD) : parseFloat(producto.ProdPrecN);
            return total + precio * producto.Cantidad;
        }, 0);
        return formatter.format(total);
    };

    useEffect(() => {
        const carritoLS = JSON.parse(localStorage.getItem('carrito')) || [];
        setCarrito(carritoLS)
    }, [])

    useEffect(() => {
        totalCarrito()
    }, [carrito])


    return (
        <CarritoContext.Provider
            value={{
                carrito, setCarrito,
                mostrarCarrito, setMostrarCarrito,
                abrirCarrito, cerrarCarrito,aniadirPack,
                aniadirProducto, eliminarProducto,
                vaciarCarrito,
                aniadirCantidad, disminuirCantidad,
                totalCarrito,
                compraValida, setCompraValida
            }}
        >
            {children}
        </CarritoContext.Provider>
    )
}

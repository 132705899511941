import { Link } from 'react-router-dom';
import { formatter } from '../resources/funciones';
import { ContadorProducto } from './ContadorProducto';
import { useContext, useState } from 'react';
import { CarritoContext } from '../context/CarritoContext';


export const Card = ({ ProdCod, ProdNom, ProdPrecN, ProdPrecD, ProdImg, ProdNue, p }) => {
    
    const { aniadirProducto,aniadirPack } = useContext(CarritoContext)

    const [contador, setContador] = useState(1)

    return (
        <div className="card border border-3 rounded-3">
            {
                ProdNue?(<span className="bg-secundario" style={{position:"absolute",zIndex:'1',width:"100%" ,textAlign: "center", color: "white", fontSize: "0.9rem",borderRadius:".5rem",margin:".1rem" }}>Nueva Presentación</span>):(null)                
            }
            {
                ProdPrecD?(<span style={{position:"absolute",zIndex:'2',width:"100%",backgroundColor:"#4ca030" ,textAlign: "center", color: "white", fontSize: "0.9rem",borderRadius:".5rem",margin:".1rem" }}>Promoción</span>):(null)
            }

            <div className="d-flex justify-content-center mt-4" data-mdb-ripple-color="light">
                <Link /* className="animate__animated animate__pulse" */
                    to={`/producto/${ProdCod}`}
                >
                    <img src={ProdImg} className="img-card img-tam"/>
                </Link>

            </div>
            <div className="card-body">
                <Link className="text-reset" to={`/producto/${ProdCod}`}>
                    <h5 className="card-title mb-3">{ProdNom}</h5>
                </Link>
                <h6 className="mb-1 text-center mb-2">
                    {
                        (!ProdPrecD) ? (
                            <strong style={{ color: "#052F3E" }}>{formatter.format(ProdPrecN)}</strong>
                        ) : (
                            <>
                                <s style={{ fontSize: "14px", color: "rgb(128,128,128)" }}>{formatter.format(ProdPrecN)}</s>
                                <strong className="ms-2" style={{ color: "#052F3E" }}>{formatter.format(ProdPrecD)}</strong>
                            </>
                        )
                    }

                </h6>

                <ContadorProducto idProducto={ProdCod} margen={"mx-auto"} setContador={setContador} contador={contador} />
                {/*------------------------- Codigo Agregado ----------------------------- */}
                <div className="botones-comprar my-2">
                    <button type="button" className="btn bg-principal w-100 text-white" onClick={() =>p.ProdPack? aniadirPack(p,contador):aniadirProducto(p, contador)}>Agregar al carrito</button>
                </div>
            </div>
        </div>
    )
}

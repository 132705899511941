import React,{useState,useRef,useEffect} from 'react'

export default function AutoComplete({data=[],saveData=()=>{},placeholder='',required=false}) {    
    const [showSelection, setShowSelection] = useState('');
    const [showDrop,setShowDrop] = useState(false)
    const [filData, setFilData] = useState([]);

    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    
    const customDrpRef = useRef(null)
    const optionsRefs = useRef([]);
    useOutsideAlerter(customDrpRef,handleClose)

    function changeShowDrop(){   
        setFilData(data)    
        setShowDrop(!showDrop)
    }

    function handleClose(){
        setShowDrop(false)
        setHighlightedIndex(-1);
    }

    function handleSelect(option){
        setShowSelection(option)
        saveData(option)
        setShowDrop(false)
    }

    function handleSearch(value){        
        if(value !== ''){
            setShowDrop(true)
            setShowSelection(value)
            const newData = data.filter(item=>item.toLowerCase().includes(value.toLowerCase()))            
            setFilData(newData)
            setHighlightedIndex(-1);
        }else{
            setShowSelection(value)
            setFilData(data)
            setHighlightedIndex(-1);
        }
    }

    function handleClean(){
        setShowSelection('')
    }

    function handleKeyDown(e) {
        
        if (e.key === 'ArrowDown') {
            e.preventDefault()
            if(highlightedIndex < filData.length -1 ){
                setHighlightedIndex(highlightedIndex + 1)
                scrollToHighlighted(highlightedIndex + 1)
            }            
            //setHighlightedIndex(prevIndex => (prevIndex < filData.length - 1 ? prevIndex + 1 : prevIndex));
            

        } else if (e.key === 'ArrowUp') {
            e.preventDefault()
            if(highlightedIndex > 0){
                setHighlightedIndex(highlightedIndex - 1)
                scrollToHighlighted(highlightedIndex - 1)
            }
            //setHighlightedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : -1));
        } else if (e.key === 'Enter') {
          if (highlightedIndex >= 0 && highlightedIndex < filData.length) {
            handleSelect(filData[highlightedIndex]);
          }
        } else if (e.key === 'Escape' || e.key == 'Tab'){
            setShowDrop(false)
        }
    }

    function scrollToHighlighted(index) {
        if (optionsRefs.current[index]) {
          optionsRefs.current[index].scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          });
        }
    }
    
    return(
        <div ref={customDrpRef} className='form-floating' onKeyDown={handleKeyDown}>
            <input 
                key='box' 
                className='form-select'
                value={showSelection}
                placeholder='Seleccionar Distrito' 
                onChange={e=>handleSearch(e.target.value)}
                onClick={()=>changeShowDrop()}
                onKeyDown={handleKeyDown}/>
            <label>{placeholder} {required?<span style={{ color: "red" }}>*</span>:null}</label>
            {showSelection !== '' && <div style={{position:"absolute",top:"30%",right:"10%",cursor:"pointer"}} onClick={()=>handleClean()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                </svg>
            </div>}
            <div key="drop" style={showDrop?{position:"absolute",height:"max-content",maxHeight:"10rem",width:'100%',overflow:"auto",zIndex:"1",backgroundColor:"white",border:"1px solid #ced4da"}:{display:"none"}}>
                {
                filData.map((option,index)=>                                    
                    <div 
                        key={option}
                        ref={el => (optionsRefs.current[index] = el)}
                        className={`autoComplete-option ps-2 mb-1 ${highlightedIndex === index ? 'autoComplete-option-select' : ''}`}
                        onClick={()=>handleSelect(option)}>                        
                        <div>{option}</div>                        
                    </div>)
                }
            </div>
        </div>
    )
}


function useOutsideAlerter(ref,callback=()=>{}) {
    useEffect(() => {       
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {                                              
                callback() 
            }
        }
        
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
}
import { keyCryptoJS } from "../config/keyCrypto";
import CryptoJS from "crypto-js"

export const encriptar = (objeto) => {
    try {
      // Serializa el objeto a JSON antes de encriptarlo
      const objetoSerializado = JSON.stringify(objeto);
      const textoCifrado = CryptoJS.AES.encrypt(objetoSerializado, keyCryptoJS).toString();
      return textoCifrado;
    } catch (error) {
      return null;
    }
  };
  
  export const desencriptar = (objeto) => {
    try {
  
      const bytes = CryptoJS.AES.decrypt(objeto, keyCryptoJS);
      const objetoSerializado = bytes.toString(CryptoJS.enc.Utf8);
  
      // Deserializa el JSON de nuevo a un objeto
      const objetoDeserializado = JSON.parse(objetoSerializado);
      return objetoDeserializado;
    } catch (error) {
      return null;
    }
  };

  export const setLocalStorage = (key,data) =>{
    try {
      localStorage.setItem(key,JSON.stringify(data))
      return true
    } catch (error) {
      return false
    }
  }

  export const getLocalStorage = (key) =>{
    try {
      const ls = JSON.parse(localStorage.getItem(key))
      return ls
    } catch (error) {
      return false
    }
  }

  export const removeLocalStorage = (key) =>{
    try {
      localStorage.removeItem(key)    
    } catch (error) {
      return false
    }
  }